import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "@/store/auth";

export const http = axios.create({
  baseURL: 'https://api-admin.showdepremiosourolux.com.br/api',
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin" : "*"
    },
});

http.interceptors.request.use(async function (config:any) {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
   
  return config;
});


http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const store = useAuth();
    if (!error.response.status || error.response.status == 401) {
      store.logout();
      Swal.fire({
        icon: "warning",
        title: "Aviso",
        text:
          error.response.data.message ||
          "Ocorreu um erro inesperado, favor contactar o suporte.",
      }).then((result) => {
        if (result.isConfirmed) {
          store.logout();
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
    return { data: error.response.data, code: error.response.status };
  }
);

export const setBearerToken = (token: string) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
