<template>
    <router-link
    :data-bs-toggle="collapse ? 'collapse' : ''"
    :to="{name: routeName}"
    :aria-controls="collapseRef"
    :aria-expanded="isExpanded"
    class="nav-link"
    v-bind="$attrs"
    @click="isExpanded = !isExpanded"
  >
    <div
      class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
    >
      <slot name="icon"></slot>
    </div>
    <span
      class="nav-link-text"
      :class="store.isRTL ? ' me-1' : 'ms-1'"
      >{{ navText }}</span
    >
  </router-link>
  <div :class="isExpanded ? 'collapse show' : 'collapse'">
    <slot name="list"></slot>
  </div>
</template>

<script setup lang="ts">
import {ref,defineProps} from 'vue';
import { useTemplate } from "@/store/template";

const store = useTemplate();

 defineProps({
    routeName: {
      type: String,
      required: true
    },
    collapseRef: {
      type: String,
      required: true
    },
    navText: {
      type: String,
      required: true
    },
    collapse: {
      type: Boolean,
      default: true
    }
})

const isExpanded = ref<boolean>(false)
</script>
