<template>
  <ContainerBox>
    <div class="row">
      <div class="col-lg-12">
        <FilterData @filtrar="(data1, data2) => filtrar(data1, data2)" />
        <SpinnerBox v-if="store.loading" />

        <div v-if="!store.loading">
          <p class="text-bold">Comportamento Usuários</p>
        </div>

        <div class="row gap-2" v-if="!store.loading">
          <mini-statistics-card
            title="Cadastros sem cupom fiscal"
            :value="store.infos.totalUsersWithoutAssign"
            icon="ni ni-circle-08"
          />
          <mini-statistics-card
            title="Cadastros pessoa Fisica"
            :value="store.infos.totalUsersWithCPF"
            icon="ni ni-circle-08"
          />
          <!-- <mini-statistics-card
            title="Cadastros pessoa Jurídica"
            :value="store.infos.totalUsersWithCNPJ"
            icon="ni ni-circle-08"
          /> -->

          <mini-statistics-card
            title="Cadastros que aceitaram receber comunicação"
            :value="store.infos.totoalUsersInfoAccepted"
            icon="ni ni-circle-08"
            :btnDownload="true"
            @click="() => download()"
          />
        </div>

        <div class="row gap-2 my-4" v-if="!store.loading">
          <mini-statistics-card
            title="Cupons Enviados"
            :value="store.infos.sentCoupons"
            icon="ni ni-bag-17"
          />


          <mini-statistics-card
            title="Números da Sorte Distribuidos"
            :value="store.statusAward.countNumbers"
            icon="ni ni-trophy"
          />

          <!-- <mini-statistics-card
            title="Cupons Ecommerce"
            :value="store.infos.ecommerce"
            icon="ni ni-bag-17"
          />

          <mini-statistics-card
            title="Cupons Loja Física"
            :value="store.infos.physical"
            icon="ni ni-bag-17"
          /> -->

           <mini-statistics-card
            title="Prêmios Instantâneos Distribuidos"
            :value="store.statusAward.countDistributed"
            icon="ni ni-satisfied"
          />
        </div>

        <!-- <div class="col-lg-7 mb-lg my-4" v-if="!store.loading">
          <p class="text-bold">Status Premiações</p>
        </div> -->

        <!-- <div class="row gap-2" v-if="!store.loading">
         

         <mini-statistics-card
            title="R$ 250 PICPAY"
            :value="store.statusAward.countPicpay"
            icon="ni ni-money-coins"
          />

          <mini-statistics-card
            title="R$ 250 ECOMMERCE"
            :value="store.statusAward.countEcommerce"
            icon="ni ni-money-coins"
          /> 

          <mini-statistics-card
            title="Números da Sorte Distribuidos"
            :value="store.statusAward.countNumbers"
            icon="ni ni-trophy"
          />
        </div> -->
      </div>
    </div>
  </ContainerBox>
</template>

<script setup lang="ts">
import { onMounted, defineAsyncComponent, ref } from "vue";
import { useDashboard } from "@/store/dashboard";
import MiniStatisticsCard from "@/components/Cards/MiniStatisticsCard.vue";
import {
  formatDateComplete,
  FormataStringData,
  dateInitalAndDate,
} from "@/utils/dates";
import FilterData from "@/components/FilterData.vue";

const SpinnerBox = defineAsyncComponent(
  () => import("@/components/Spinner.vue")
);
const ContainerBox = defineAsyncComponent(
  () => import("@/components/Container/Container.vue")
);

const store = useDashboard();

const filtrar = (data1: any, data2: any) => {
  store.getData(data1, data2);
};

onMounted(() => {
  const { dataInicialCompleta, dataFinalCompleta } = dateInitalAndDate();
  store.getData(dataInicialCompleta, dataFinalCompleta);
});

function download() {
  const { dataInicialCompleta, dataFinalCompleta } = dateInitalAndDate();
  store.downloadExcel(dataInicialCompleta, dataFinalCompleta);
}
</script>

<style scoped>
button:hover {
  filter: brightness(0.92);
}
</style>
