export function padTo2Digits(num: any) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date: any) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth()),
    date.getFullYear(),
  ].join("/");
}

export function formatDateMonth(date: any) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() - 1),
    date.getFullYear(),
  ].join("/");
}

export function formatDateComplete(date) {
  if (!(date instanceof Date)) {
    throw new Error('Invalid "date" argument. You must pass a date instance');
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function formatDates(date = new Date()) {
  const year = date.toLocaleString("default", { year: "numeric" });
  const month = date.toLocaleString("default", { month: "2-digit" });
  const day = date.toLocaleString("default", { day: "2-digit" });

  return [year, month, day].join("-");
}

export function dateInitalAndDate() {
  const date = new Date();
  const dateInicial = new Date(date.getFullYear(), date.getMonth(), 1);
  const dateFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const firstDayDate = dateInicial.toLocaleDateString();
  const finalDayDate = dateFinal.toLocaleDateString();

  const dataInicialCompleta = FormataStringData(firstDayDate);
  const dataFinalCompleta = FormataStringData(finalDayDate);

  return {dataInicialCompleta,dataFinalCompleta}
}


export function FormataStringData(data:any) {
    const dia  = data.split("/")[0];
    const mes  = data.split("/")[1];
    const ano  = data.split("/")[2];
  
    return ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);
  }