<template>
  <ContainerBox>
    <FilterData @filtrar="(data1, data2) => filtrar(data1, data2)" />

    <div class="col-lg-12" v-if="store.loading">
      <SpinnerBox />
    </div>

    <div class="col mt-2" v-if="!store.loading">
      <p class="text-bold">Participações por Estado</p>
    </div>

    <div>
      <BarCharBox
        v-if="!store.loading"
        :labels="store.estadosList"
        :data="store.pontosList"
        :aspectio-ratio="store.estadosList.length >= 5 ? 2 : 2"
      />
    </div>

    <div class="mt-2 mt-5 mb-5" v-if="!store.loading">
      <p class="text-bold">Participações por Cidade</p>
    </div>

    <BarCharBox
      v-if="!store.loading"
      :labels="store.cidadeList"
      :data="store.pontosCidadeList"
      type-chart="y"
      :aspectio-ratio="store.cidadeList.length >= 5 ? 0.2 : 2"
    />
  </ContainerBox>
</template>

<script setup lang="ts">
import { onMounted, defineAsyncComponent } from "vue";
import { useDashboard } from "@/store/dashboard";
import FilterData from "@/components/FilterData.vue";
import { dateInitalAndDate } from "@/utils/dates";

const SpinnerBox = defineAsyncComponent(
  () => import("@/components/Spinner.vue")
);
const ContainerBox = defineAsyncComponent(
  () => import("@/components/Container/Container.vue")
);
const BarCharBox = defineAsyncComponent(
  () => import("@/components/BarChart/BarChart.vue")
);

const store = useDashboard();

onMounted(() => {
  const { dataInicialCompleta, dataFinalCompleta } = dateInitalAndDate();
  store.getPerCity(dataInicialCompleta, dataFinalCompleta);
});

const filtrar = (data1: any, data2: any) => {
  store.getPerCity(data1, data2);
};
</script>

<style scoped></style>
