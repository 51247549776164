<template>
  <div class="col card shadow border rounded-2">
    <div class="p-3">
      <div class="d-flex justify-content-between gap-2">
        <div :style="titleLong ? 'width: 200px;' : 'width:110px;'">
          <p
            class="mb-0 text-sm text-uppercase font-weight-bold"
            :class="title.color"
          >
            {{ typeof title === "string" ? title : title.text }}
          </p>

          <h5 :class="`mb-0 font-weight-bolder mt-2 ${value.color}`">
            {{ value }}
          </h5>
        </div>

        <div
          class="d-flex flex-column justify-content-between align-items-center"
        >
          <div
            class="rounded-circle icon-md"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #ff8f00;
            "
          >
            <i class="text-white" :class="icon" style="font-size: 20px"></i>
          </div>

          <i
            v-if="btnDownload"
            class="ni ni-cloud-download-95 text-black mb-2"
            style="font-size: 20px; cursor: pointer"
            @click.prevent="$emit('click')"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MiniStatisticsCard",
  emits: ["click"],
  props: {
    btnDownload: {
      type: Boolean,
      default: false,
      required: false,
    },
    titleLong: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: [Object, String],
      required: true,
      text: {
        type: String,
      },
      color: {
        type: String,
      },
    },
    description: {
      type: String,
      default: "",
    },
    value: {
      type: [Object, String, Number],
      required: true,
      text: {
        type: [String, Number],
      },
      color: {
        type: String,
      },
    },
    icon: {
      type: String,

      default: "ni ni-trophy",
    },
    classContent: {
      type: String,
      default: "",
    },
  },
};
</script>
