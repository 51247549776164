import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { defineStore } from "pinia";
import FileDownload from 'js-file-download';
import { AxiosResponse } from "axios";
import { http } from "@/services/api";
import Swal from "sweetalert2";
import { formatDate } from "@/utils/dates";


export const useDashboard = defineStore("dashboard", () => {
  const router = useRouter();
  const loading = ref(false);
  const infos = reactive({
    ecommerce: 0,
    physical: 0,
    sentCoupons: 0,
    totalUsers: 0,
    totalUsersWithCNPJ: 0,
    totalUsersWithCPF: 0,
    totalUsersWithoutAssign: 0,
    totoalUsersInfoAccepted: 0,
  });

  const statusAward = reactive({
    countDistributed: 0,
    countEcommerce: 0,
    countNumbers: 0,
    countPicpay: 0,
  });

  const estadosList = ref([]);
  const pontosList = ref([]);

  const cidadeList = ref([]);
  const pontosCidadeList = ref([]);

  const idadeList = ref([]);
  const pontosIdadeList = ref([]);

  const produtosList = ref([]);
  const pontosProdutosList = ref([]);

  function showMsg(icon: any, title: any, text: any) {
    Swal.fire({
      icon: icon,
      title,
      text,
    });
  }

  async function getData(dataUntil:any,dataFrom:any) {
    loading.value = true;
    try {
      const until = dataUntil.split('-')
      const from = dataFrom.split('-')

      const data1 = formatDate(new Date(until[0], until[1], until[2]))
      const data2 = formatDate(new Date(from[0], from[1], from[2]))

      
      const response: AxiosResponse = await http.get(
        `/users/behavior?until=${data2}&from=${data1}`
      );
      const responseAwards: AxiosResponse = await http.get(`/voucher/awards?until=${data2}&from=${data1}`);

      infos.ecommerce = response.data.body.ecommerce;
      infos.physical = response.data.body.physical;
      infos.sentCoupons = response.data.body.sentCoupons;
      infos.totalUsers = response.data.body.totalUsers;
      
      infos.totalUsersWithCNPJ = response.data.body.totalUsersWithCNPJ;
      infos.totalUsersWithCPF = response.data.body.totalUsersWithCPF;
      infos.totalUsersWithoutAssign = response.data.body.totalUsersWithoutAssign;
      infos.totoalUsersInfoAccepted = response.data.body.totoalUsersInfoAccepted;

      statusAward.countDistributed = responseAwards.data.body.countDistributed;
      statusAward.countEcommerce = responseAwards.data.body.countEcommerce;
      statusAward.countNumbers = responseAwards.data.body.countNumbers;
      statusAward.countPicpay = responseAwards.data.body.countPicpay;

    } catch (error) {
      showMsg("error", "Aviso", "erro ao buscar dados da dashboard");
    } finally {
      loading.value = false;
    }
  }

  async function getPerCity(dataUntil:any,dataFrom:any) {
    loading.value = true;
    try {
      const until = dataUntil.split('-')
      const from = dataFrom.split('-')

      const data1 = formatDate(new Date(until[0], until[1], until[2]))
      const data2 = formatDate(new Date(from[0], from[1], from[2]))
      const response: AxiosResponse = await http.get(`/users/by-city?until=${data2}&from=${data1}`);
      const responseAState: AxiosResponse = await http.get(`/users/by-state?until=${data2}&from=${data1}`);

      const estados = responseAState.data.body.map((e: any) => {
        return e.estado;
      });

      estadosList.value = estados;

      const pontosEstados = responseAState.data.body.map((e: any) => {
        return e.Total;
      });

      pontosList.value = pontosEstados;

      const cidade = response.data.body.map((e: any) => {
        return e.cidade;
      });

      cidadeList.value = cidade;

      const pontos = response.data.body.map((e: any) => {
        return e.Total;
      });

      pontosCidadeList.value = pontos;
    } catch (error) {
      showMsg("error", "Aviso", "Erro ao buscar dados do gráfico");
    } finally {
      loading.value = false;
    }
  }

  async function getPerAge(dataUntil:any,dataFrom:any) {
    loading.value = true;
    try {
      const until = dataUntil.split('-')
      const from = dataFrom.split('-')

      const data1 = formatDate(new Date(until[0], until[1], until[2]))
      const data2 = formatDate(new Date(from[0], from[1], from[2]))
      const response: AxiosResponse = await http.get(`/users/by-age?until=${data2}&from=${data1}`);

      const estados = response.data.body.map((e: any) => {
        return e.idade;
      });

      idadeList.value = estados;

      const pontos = response.data.body.map((e: any) => {
        return e.total;
      });

      pontosIdadeList.value = pontos;
    } catch (error) {
      showMsg("error", "Aviso", "Erro ao buscar dados do gráfico");
    } finally {
      loading.value = false;
    }
  }

  async function getPerProducts(dataUntil:any,dataFrom:any) {
    loading.value = true;
    try {
      const until = dataUntil.split('-')
      const from = dataFrom.split('-')

      const data1 = formatDate(new Date(until[0], until[1], until[2]))
      const data2 = formatDate(new Date(from[0], from[1], from[2]))
      const response: AxiosResponse = await http.get(`/product/by-assign?until=${data2}&from=${data1}`);

      const estados = response.data.body.map((e: any) => {
        return e.produtoName;
      });

      produtosList.value = estados;

      const pontos = response.data.body.map((e: any) => {
        return e.totalQty;
      });

      pontosProdutosList.value = pontos;
    } catch (error) {
      showMsg("error", "Aviso", "Erro ao buscar dados do gráfico");
    } finally {
      loading.value = false;
    }
  }

  async function downloadExcel(dataUntil:any,dataFrom:any) {
    try {
      const res = await http.get(`users/total-users-info-accepted`, {
        responseType: 'blob',
       
      });
      const blob = new Blob([res.data]);

      FileDownload(blob, 'modelo.xls');
    } catch (error) {
      showMsg("error", "Aviso", "erro ao baixar o arquivo");
    }
  }

  return {
    getData,
    getPerCity,
    getPerAge,
    getPerProducts,
    downloadExcel,
    loading,
    infos,
    statusAward,
    estadosList,
    pontosList,
    cidadeList,
    pontosCidadeList,
    idadeList,
    pontosIdadeList,
    produtosList,
    pontosProdutosList,
  };
});
