<template>
  <p class="text-bold">Filtrar por Data:</p>
  <div class="row gap-2 align-items-center mb-4">
    <div class="col-3" style="margin: 0; padding-left: 8px">
      <ArgonInput label="De" type="date" placeholder="Date" v-model="de" />
    </div>
    <div class="col-3" style="margin: 0">
      <ArgonInput label="Até" type="date" placeholder="Date" v-model="ate" />
    </div>
    <div class="col-3" style="margin: 0">
      <button
        class="rounded-2 border-0 p-1 w-40 text-white shadow-lg"
        style="background-color: #ff8f00; margin-top: 15px"
        @click.prevent="filtrar"
      >
        Filtrar
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { dateInitalAndDate } from "@/utils/dates";
import { onMounted, ref, defineEmits } from "vue";
import ArgonInput from "./ArgonInput.vue";
const de = ref("");
const ate = ref("");

const emits = defineEmits(["filtrar"]);

onMounted(() => {
  const { dataInicialCompleta, dataFinalCompleta } = dateInitalAndDate();

  de.value = dataInicialCompleta;
  ate.value = dataFinalCompleta;
});

const filtrar = () => {
  emits("filtrar", de.value, ate.value);
};
</script>

<style scoped></style>
