<template>
  <SideBar v-if="route.path !== '/login'" />

  <main class="main-content position-relative max-height-vh-100 h-100">
    <NavBar v-if="route.path !== '/login'" />
    <router-view />
  </main>
</template>
<script setup lang="ts">
import { useRoute } from "vue-router";
import NavBar from "./components/NavBar/NavBar.vue";
import SideBar from "./components/SideBar/SideBar.vue";

const route = useRoute();
</script>
