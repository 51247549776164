<template>
  <nav
    id="navbarBlur"
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky left-auto top-1 z-index-sticky align-items-center d-flex justify-content-center bre"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-1 py-1 container-fluid">
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-none d-sm-block d-md-block"
      >
        <a
          href="#"
          class="p-0 nav-link text-body"
          @click.prevent="store.handleNav"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line bg-white"></i>
            <i class="sidenav-toggler-line bg-white"></i>
            <i class="sidenav-toggler-line bg-white"></i>
          </div>
        </a>
      </div>

      <!-- <div class="menu">
        <breadcrumbs
          :current-page="currentRouteName"
          :current-directory="currentDirectory"
        />
      </div> -->

      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
      >
        <ul class="ms-md-auto navbar-nav justify-content-end">
          <li class="nav-item dropdown d-flex align-items-center pe-2">
            <argon-button
              class="border-radius-md text-white cursor-pointer"
              @click.prevent="logout"
            >
              <div class="py-1 d-flex justify-content-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-1 text-sm font-weight-normal text-white">
                    Sair
                  </h6>
                </div>
                <div class="my-auto ms-3">
                  <i class="fas fa-sign-out-alt"></i>
                </div>
              </div>
            </argon-button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useNav } from "@/store/navbar";
import { useAuth } from "@/store/auth";
import Breadcrumbs from "./Breacrumbs.vue";

const route = useRoute();
const store = useNav();
const auth = useAuth();

const currentRouteName = computed(() => {
  let dir = route.path.split("/");

  if (dir.length === 2) {
    return "";
  }
  return route.name as string;
});

const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];

  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

function logout() {
  auth.logout();
}
</script>

<style scoped>
.menu {
  margin: 0;
}

.bre {
  z-index: 2;
}

@media (min-width: 320px) and (max-width: 820px) {
  .menu {
    margin-left: 0.5rem;
  }
}
</style>
