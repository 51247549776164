<template>
  <div class="form-group">
    <label for="selectLabel" class="form-label label-text">{{ label }}</label>
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input
        :id="id"
        :value="modelValue"
        :type="type"
        class="form-control"
        :class="getClasses(size, success, error)"
        :name="name"
        v-bind="attributes"
        :placeholder="placeholder"
        :isRequired="isRequired"
        v-on="listeners"
      />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
    <div
      v-if="error"
      class="form-text"
      :class="error && 'text-danger mx-1'"
      id="basic-addon4"
    >
      {{ errorlabel }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import omit from "@/utils/omit";

import {
  defineProps,
  defineEmits,
  ref,
  computed,
  withDefaults,
  useAttrs,
} from "vue";
interface IProps {
  label?: string;
  errorlabel?: string;
  modelValue?: any;
  type?: string;
  size?: string;
  success?: boolean;
  error?: boolean;
  icon?: string;
  iconDir?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  isRequired?: boolean;
  nullable?: boolean;
  trim?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
  modelValue: "",
  type: "text",
  label: "",
});
const emit = defineEmits<{
  (e: "update:modelValue", value: string | number | null): void;
  (e: "click", event: MouseEvent): void;
  (e: "keydown", event: KeyboardEvent): void;
  (e: "focus", event: PointerEvent): void;
  (e: "update:hint", value: string): void;
}>();
const attrs = useAttrs();

const input = ref<HTMLInputElement | null>(null);
const processValue = (event: KeyboardEvent) => {
  if (!event.key) return;
  //   const key = event.key.toLocaleLowerCase()
  //   const systemKeys = ['meta', 'shift', 'alt', 'backspace', 'delete', 'tab']
  //   const value = (event.target as HTMLInputElement).value

  //   if (props.slug === true) {
  //   }

  //   if (props.dbSafe === true) {
  //   }

  emit("keydown", event);
};
const emitValue = (event: InputEvent) => {
  const value = (event.target as HTMLInputElement).value;

  if (props.nullable === true && value === "") {
    emit("update:modelValue", null);
    return;
  }

  if (props.type === "number") {
    const parsedNumber = Number(value);

    if (Number(props.modelValue) !== parsedNumber)
      emit("update:modelValue", parsedNumber);
  } else {
    // if (props.slug === true) {
    // }

    // if (props.dbSafe === true) {
    // }

    emit("update:modelValue", value);
  }
};
const trimIfEnabled = () => {
  if (props.modelValue && props.trim && ["string", "text"].includes(props.type))
    emit("update:modelValue", String(props.modelValue).trim());
};
const listeners = computed(() => ({
  input: emitValue,
  keydown: processValue,
  blur: (e: Event) => {
    trimIfEnabled();
    if (typeof attrs.onBlur === "function") attrs.onBlur(e);
  },
  focus: (e: PointerEvent) => emit("focus", e),
}));
const attributes = computed(() => omit(attrs, "class"));

function getClasses(size, success, error) {
  let sizeValue, isValidValue;

  sizeValue = props.size ? `form-control-${props.size}` : null;

  if (error) {
    isValidValue = "is-invalid";
  } else if (success) {
    isValidValue = "is-valid";
  } else {
    isValidValue = "";
  }

  return `${sizeValue} ${isValidValue}`;
}
const getIcon = (icon) => (icon ? icon : null);
const hasIcon = (icon) => (icon ? "input-group" : null);
</script>

<style scoped>
.label-text {
  font-size: 0.9rem;
}
</style>
