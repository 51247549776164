import { defineStore } from "pinia";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export const useTemplate = defineStore("template", {
  state: () => ({
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    isRTL: false,
    color: "",
    sidebarType: "bg-white",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: false,
    showSidenav: false,
    showNavbar: false,
    showFooter: true,
    showMain: true,
    layout: "default",
    bootstrap,
  }),
  actions: {
    toggleConfigurator() {
      this.showConfig = !this.showConfig;
    },
    navbarMinimize() {
      const sidenav_show:HTMLElement | null = document.querySelector("#app") as HTMLElement | null;

      if (
        sidenav_show?.classList.contains("g-sidenav-show") &&
        sidenav_show?.classList.contains("g-sidenav-hidden")
      ) {
        sidenav_show?.classList.add("g-sidenav-pinned");
        sidenav_show?.classList.remove("g-sidenav-hidden");
        this.isPinned = true;
      } else if (
        sidenav_show?.classList.contains("g-sidenav-show") &&
        sidenav_show?.classList.contains("g-sidenav-pinned")
      ) {
        sidenav_show?.classList.add("g-sidenav-hidden");
        sidenav_show?.classList.remove("g-sidenav-pinned");
        this.isPinned = false;
      } else if (
        sidenav_show?.classList.contains("g-sidenav-show") &&
        window.innerWidth < 1200
      ) {
        sidenav_show.classList.add("g-sidenav-pinned");
        this.isPinned = true;
      } else {
        sidenav_show?.classList.add("g-sidenav-hidden");
        this.isPinned = true;
      }
    },
    setSidebarType(payload) {
      this.sidebarType = payload;
    },
    navbarFixed() {
      if (this.isNavFixed === false) {
        this.isNavFixed = true;
      } else {
        this.isNavFixed = false;
      }
    },
    toggleDefaultLayout() {
      this.showNavbar = !this.showNavbar;
      this.showSidenav = !this.showSidenav;
      this.showFooter = !this.showFooter;
    },
  },
});
