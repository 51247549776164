<template>
  <ContainerBox>
    <FilterData @filtrar="(data1, data2) => filtrar(data1, data2)" />
    <div class="col-lg-12" v-if="store.loading">
      <SpinnerBox />
    </div>

    <div class="mt-2 mb-4" v-if="!store.loading">
      <p class="text-bold text-uppercase">total de produtos cadastrados</p>
    </div>

    <BarCharBox
      v-if="!store.loading"
      :labels="store.produtosList"
      :data="store.pontosProdutosList"
      type-chart="y"
      :aspectio-ratio="store.produtosList.length >= 5 ? 0.8 : 2"
    />
  </ContainerBox>
</template>

<script setup lang="ts">
import { onMounted, defineAsyncComponent } from "vue";
import { useDashboard } from "@/store/dashboard";
import { dateInitalAndDate } from "@/utils/dates";
import FilterData from "@/components/FilterData.vue";

const SpinnerBox = defineAsyncComponent(
  () => import("@/components/Spinner.vue")
);
const ContainerBox = defineAsyncComponent(
  () => import("@/components/Container/Container.vue")
);
const BarCharBox = defineAsyncComponent(
  () => import("@/components/BarChart/BarChart.vue")
);

const store = useDashboard();

onMounted(() => {
  const { dataInicialCompleta, dataFinalCompleta } = dateInitalAndDate();
  store.getPerProducts(dataInicialCompleta, dataFinalCompleta);
});

const filtrar = (data1: any, data2: any) => {
  store.getPerProducts(data1, data2);
};
</script>

<style scoped></style>
