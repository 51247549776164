<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item" v-for="item in links" :key="item.id">
        <sidenav-collapse
          :nav-text="item.navText"
          :collapse="item.collapse"
          :url="item.url"
          :aria-controls="item.ariaControls"
          :route-name="item.routeName"
          :collapse-ref="item.collapseRef"
        >
          <template #icon>
            <font-awesome-icon
            :icon="item.icon"
            style="color: #67748e"
          />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useTemplate } from "@/store/template";
import { links } from "./menu";
import SidenavCollapse from "./SideNavCollapse.vue";

const store = useTemplate();
</script>

<style scoped></style>
