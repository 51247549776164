export const links = [
  {
    id: 1,
    navText: "Dashboard",
    collapse: false,
    url: "#",
    ariaControls: "''",
    routeName: "Dashboard",
    collapseRef: "/dashboard",
    icon: ["fass", "chart-simple"],
  },
  {
    id: 2,
    navText: "Participações por Localidade",
    collapse: false,
    url: "#",
    ariaControls: "''",
    routeName: "PerCity",
    collapseRef: "/per-city",
    icon: ["fass", "chart-simple"],
  },
  {
    id: 3,
    navText: "Participações por Idade",
    collapse: false,
    url: "#",
    ariaControls: "''",
    routeName: "PerAge",
    collapseRef: "/per-age",
    icon: ["fass", "chart-simple"],
  },
  {
    id: 4,
    navText: "Produtos Cadastrados",
    collapse: false,
    url: "#",
    ariaControls: "''",
    routeName: "PerProduct",
    collapseRef: "/por-produto",
    icon: ["fass", "chart-simple"],
  },
  // {
  //   id: 2,
  //   navText: "Usuários",
  //   collapse: false,
  //   url: "#",
  //   ariaControls: "''",
  //   routeName: "Usuários",
  //   collapseRef: "/usuarios",
  //   icon: ["fass", "user"],
  // },
  // {
  //   id: 3,
  //   navText: "Administradores",
  //   collapse: false,
  //   url: "#",
  //   ariaControls: "''",
  //   routeName: "Admin",
  //   collapseRef: "/admin",
  //   icon: ["fass", "lock"],
  // },
  // {
  //   id: 4,
  //   navText: "Produtos",
  //   collapse: false,
  //   url: "#",
  //   ariaControls: "''",
  //   routeName: "Produtos",
  //   collapseRef: "/produtos",
  //   icon: ["fass", "box"],
  // },
  // {
  //   id: 5,
  //   navText: "Dúvidas",
  //   collapse: false,
  //   url: "#",
  //   ariaControls: "''",
  //   routeName: "Duvidas",
  //   collapseRef: "/duvidas",
  //   icon: ["fass", "comment"],
  // },
  // {
  //   id: 6,
  //   navText: "Privacidade",
  //   collapse: false,
  //   url: "#",
  //   ariaControls: "''",
  //   routeName: "Privacidade",
  //   collapseRef: "/privacidade",
  //   icon: ["fass", "ruler"],
  // },

  // {
  //   id: 7,
  //   navText: "Regulamento",
  //   collapse: false,
  //   url: "#",
  //   ariaControls: "''",
  //   routeName: "Regulamento",
  //   collapseRef: "/regulamento",
  //   icon: ["fass", "ruler"],
  // },
];
