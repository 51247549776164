import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast, { PluginOptions } from "vue-toastification";
import router from "./router";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import { VueMaskDirective } from "v-mask";
import Paginate from "vuejs-paginate";
import { QuillEditor } from '@vueup/vue-quill'

import '@vueup/vue-quill/dist/vue-quill.snow.css';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "vue-toastification/dist/index.css";
import "./assets/css/global.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faUserSecret,
  faTrash,
  faPen,
  faBriefcase,
  faSearch,
  faUser,
  faChartSimple,
  faStore,
  faClose,
  faLock,
  faBullseye,
  faGift,
  faComment,
  faBox,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faRuler,
  faBox,
  faComment,
  faUserSecret,
  faTrash,
  faPen,
  faBriefcase,
  faSearch,
  faUser,
  faChartSimple,
  faStore,
  faClose,
  faLock,
  faBullseye,
  faGift,
);

const options: PluginOptions = {
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind,
};
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const appInstance = createApp(App);
appInstance.component("font-awesome-icon", FontAwesomeIcon);
appInstance.component("paginate", Paginate);
appInstance.component('QuillEditor', QuillEditor);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(pinia);
appInstance.use(Toast, options);
appInstance.directive("mask", vMaskV3);
appInstance.mount("#app");
