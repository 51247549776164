import { defineStore } from "pinia";
import { ref } from "vue";

export const useNav = defineStore("useNav", () => {
  const nav = ref(false);

  function handleNav() {
    nav.value = !nav.value;
    const sidenav_show: HTMLElement | null = document.querySelector(
      "#app"
    ) as HTMLElement | null;

    if (
      sidenav_show?.classList.contains("g-sidenav-show") &&
      sidenav_show?.classList.contains("g-sidenav-hidden")
    ) {
      sidenav_show?.classList.add("g-sidenav-pinned");
      sidenav_show?.classList.remove("g-sidenav-hidden");
    } else if (
      sidenav_show?.classList.contains("g-sidenav-show") &&
      sidenav_show?.classList.contains("g-sidenav-pinned")
    ) {
      sidenav_show?.classList.add("g-sidenav-hidden");
      sidenav_show?.classList.remove("g-sidenav-pinned");
    } else if (
      sidenav_show?.classList.contains("g-sidenav-show") &&
      window.innerWidth < 1200
    ) {
      sidenav_show.classList.add("g-sidenav-pinned");
    } else {
      sidenav_show?.classList.add("g-sidenav-hidden");
    }
  }

  return {
    nav,
    handleNav,
  };
});
