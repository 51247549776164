<template>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100 bg-red">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2 mb-3">Login</h5>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <form role="form" class="text-start">
                  <div class="mb-3">
                    <argon-input
                      label="E-mail"
                      v-model="form.email"
                      id="email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                    />
                  </div>
                  <div class="mb-3">
                    <argon-input
                      label="Senha"
                      id="password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      v-model="form.password"
                    />
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      class="mb-0 btn btn-link btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#import"
                    >
                      Esqueci a senha
                    </button>
                  </div>

                  <div class="text-center">
                    <spinner v-if="auth.loading" />

                    <argon-button
                      v-if="!auth.loading"
                      style="background-color: #ff8f00"
                      full-width
                      class="my-4 mb-2"
                      @click.prevent="handleLogin"
                      >Enviar</argon-button
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="import" class="modal fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-body">
          <div class="card z-index-0 mb-7">
            <div class="card-header text-center pt-4 pb-1">
              <h4 class="font-weight-bolder mb-1">Recuperar senha</h4>
              <p class="mb-0">Confirme o cpf cadastrado</p>
            </div>
            <div class="card-body">
              <form role="form">
                <argon-input
                  v-model="cpf"
                  type="text"
                  placeholder="CPF"
                  aria-label="CPF"
                />
                <div class="text-center">
                  <argon-button
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                    full-width
                    >Enviar</argon-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { ref, reactive } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";
import Spinner from "@/components/Spinner.vue";
import { useTemplate } from "@/store/template";
import { useAuth } from "@/store/auth";

const router = useRouter();
const auth = useAuth();

const cpf = ref("");

const form = reactive({
  email: "",
  password: "",
});

async function handleLogin() {
  auth.login(form.email, form.password);
}
</script>
