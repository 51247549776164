import { ref } from "vue";
import { useRouter } from "vue-router";
import { defineStore } from "pinia";
import axios, { AxiosResponse } from "axios";
import { http, setBearerToken } from "@/services/api";
import Swal from "sweetalert2";

export const useAuth = defineStore("auth", () => {
  const router = useRouter();
  const loading = ref(false);
  const token = ref(localStorage.getItem("token"));
  const isAuth = ref(false);

  function setIsAuth(auth) {
    isAuth.value = auth;
  }

  function setToken(tokenValue: string) {
    localStorage.setItem("token", tokenValue);
    token.value = tokenValue;
  }

  function logout() {
    localStorage.removeItem("token");
    token.value = "";
    isAuth.value = false;
    router.push('/login');
  }

  async function login(email: string, senha: string) {
    loading.value = true;
    try {
      const response: AxiosResponse = await http.post("/login", {
        email,
        password: senha,
      });
      console.log(response);
      if (response.data.statusCode === 400) {
        Swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "E-mail ou senha inválido. Tente novamente.",
        });
      } else {
        setToken(response.data.body.access_token);
        // setBearerToken(response.data.data.token);
        http.defaults.headers.Authorization = `Bearer ${response.data.body.access_token}`;
        
        setIsAuth(true);
        router.push({ name: "Dashboard" });
      }
    } catch (error) {
      console.log(error);
      
    } finally {
      loading.value = false;
    }
  }


  return {
    login,
    token,
    logout,
    loading,
    isAuth,
    setIsAuth
  };
});
