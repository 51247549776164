import { createRouter, createWebHistory } from "vue-router";

import Login from "../pages/auth/Login.vue";
import DashboardDefault from "../pages/dashboard/Default.vue";
import UsuariosView from "@/pages/usuarios/index.vue";
import DuvidasView from "@/pages/duvidas/index.vue";
import ListaUsuariosView from "@/pages/usuarios/Usuarios/ListausuariosView.vue";
import UsuariosVAdminiew from "@/pages/usuariosAdmin/index.vue";
import ListaAdminView from "@/pages/usuariosAdmin/Admin/Listaadmin.vue";
import ProdutosView from "@/pages/produtos/produtos.vue";
import PrivacidadeView from '@/pages/privacidade/index.vue';
import RegulamentoView from '@/pages/regulamento/index.vue';
import PerAge from '@/pages/perAge/PerAge.vue';
import PerCity from '@/pages/perCity/PerCity.vue';
import PerProduct from '@/pages/perProduct/PerProduct.vue';


import { useAuth } from "@/store/auth";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardDefault,
  },
  {
    path: "/",
    name: "Dashboard",
    component: DashboardDefault,
  },
  {
    path: "/por-idade",
    name: "PerAge",
    component: PerAge,
  },
  {
    path: "/por-cidade",
    name: "PerCity",
    component: PerCity,
  },
  {
    path: "/por-produto",
    name: "PerProduct",
    component: PerProduct,
  },
  // {
  //   path: "/usuarios",
  //   component: UsuariosView,
  //   children: [
  //     {
  //       path: "",
  //       name: "Usuários",
  //       component: ListaUsuariosView,
  //     },
  //   ],
  // },
  // {
  //   path: "/admin",
  //   component: UsuariosVAdminiew,
  //   children: [
  //     {
  //       path: "",
  //       name: "Admin",
  //       component: ListaAdminView,
  //     },
  //   ],
  // },
  // {
  //   path: "/duvidas",
  //   name: "Duvidas",
  //   component: DuvidasView,
  // },
  // {
  //   path: "/produtos",
  //   name: "Produtos",
  //   component: ProdutosView,
  // },
  // {
  //   path: "/privacidade",
  //   name: "Privacidade",
  //   component: PrivacidadeView,
  // },
  // {
  //   path: "/regulamento",
  //   name: "Regulamento",
  //   component: RegulamentoView,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuth();

  if (authRequired && !auth.token) {
    next({ name: "Login" });
  } else if (!authRequired && auth.token) {
    next({ name: "Dashboard" });
  }

  return next();
});

export default router;
