<template>
  <div
    v-show="store.layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${store.darkMode ? 'bg-trasnparent' : 'bg-success'}`"
  />

  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4 z-index-1"
    :class="`${store.sidebarType} fixed-start`"
  >
  <div class="d-xl-none d-sm-block d-md-block my-3 mx-4 d-flex justify-content-end" @click="storeNav.handleNav" >
    <font-awesome-icon :icon="['fas', 'close']" />
  </div>
    <div class="d-flex pt-3">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="col text-center" :to="{ name: 'Dashboard' }">
        <argon-avatar img="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png" alt="Avatar" size="xxl" circular />
        <p v-if="!storeNav.nav">ADMIN</p>
      </router-link>
    </div>
    <hr class="horizontal dark"  />
    <SideNavList />
  </aside>
</template>

<script setup>
import { useTemplate } from "@/store/template";
import { useNav } from "@/store/navbar";
import SideNavList from "./SideNavList.vue";
import ArgonAvatar from "../ArgonAvatar.vue";

const store = useTemplate();
const storeNav = useNav();
</script>

<style scoped>
hr {
margin: 0.5rem 0 0.5rem 0;
}

</style>
